/* 网络组件 */
const net = {

	//正式服4
	api:'https://openapi.jungong56.com/',

	//测试服
	// api:'https://apintest.jungong56.com/',

	req(obj){//暴露方法

		//骚操作
		net.out=this;

		//置入请求体
		if(!obj.data){
			obj.data={};
		}

		//置入session_id
		if(!obj.data.session_id){
			obj.data.session_id=this.__parent.cache.get('session_id')
		}

		//console.log(this.__parent.cache.get('init_status'))

		//判断是否有权限
		if(obj.data.ctr=='transit_admin_webpage_init'){//初始化请求 放行
			net.to_req(obj);
		}else if(this.__parent.cache.get('init_status')==3){//已经初始化 放行
			net.to_req(obj);
		}else{//未初始化 拦截并缓存请求
			net._before_init_post_buf.push(obj)
		}
	},
	to_req(obj){//非暴露方法
		let api_url=this.api+'?rsa_para='+this.out.__parent.rsa.encode(obj.data);
		let api1 = this.api
		let api2 = 'rsa_para='+this.out.__parent.rsa.encode(obj.data)
		if(obj.api){
			api1 = obj.api
			api_url=obj.api+"?";
			api2 = ''
			let _arr=[];
			for(var i in obj.data){
				_arr.push(i+"="+obj.data[i])
			}
			api_url+=_arr.join("&");
			api2 += _arr.join("&");
		}
		net.axios.post(api1,api2).then((res)=>{
			//take_over_control:1,//强制接管(1:接管content,2:接管整个请求返回,无此参数:不接管任何数据)

			//接管整个请求返回
			if(obj.take_over_control==2){

				//有回调
				if(this.out.__parent.check.is_fun(obj.callback)){
					obj.callback(res);
					return;
				}
			}

			//网络请求失败
			if(res.status!=200){
				this.faild(res);
				return;
			}

			//接管content
			if(obj.take_over_control==1){

				//有回调
				if(this.out.__parent.check.is_fun(obj.callback)){
					obj.callback(res.data);
					return;
				}
			}

			//网络请求成功 但是业务层失败
			if(res.data.code!=0){
				this.faild(res);
				return;
			}

			//仅返回业务层数据
			if(this.out.__parent.check.is_fun(obj.callback)){
				obj.callback(res.data.msg);
				return;
			}
		})
	},
	faild(res){//请求失败处理方法

		//网络请求失败
		if(res.status!=200){
			this.out.__parent.other.msg({
				type:'error',
				str:'网络请求失败'
			});
			return;
		}

		//网络请求成功 但是业务层失败
		if(res.data.code==2){//未登录

			//前往登录页

			//待实现

		}else if(res.data.code==3){//未实名

			//询问是否前往实名页面

			//待实现

		}else if(res.data.code==4){//未企业认证

			//询问是否前往企业认证页面

			//待实现

		}else{//普通错误
			this.out.__parent.other.msg({
				type:'warning',
				str:res.data.error_info
			});
			return;
		}
	},
	axios:require('axios'),
	_before_init_post_buf:[],
	do_before_init_post_buf(){
		if(this._before_init_post_buf.length>0){

			this.to_req({
				data:this._before_init_post_buf[0].data,
				callback:(res)=>{

					if(this._before_init_post_buf[0].callback){

						this._before_init_post_buf[0].callback(res)

					}

					this._before_init_post_buf.splice(0,1)

					this.do_before_init_post_buf()
				},
			})

		}
	},
}

export default {
	req:net.req
}